import React from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'
import Layout from '../components/Layout'
import TeamMemberTemplate from './TeamMemberTemplate'

const TeamMember = ({ data }) => {
  const {
    markdownRemark: {
      frontmatter: {
        order,
        title,
        position,
        image: { publicURL: image }
      }
    }
  } = data

  const templateData = { order, title, position, image }

  return (
    <Layout title={title} description={position}>
      <TeamMemberTemplate
        {...templateData}
        helmet={
          <Helmet titleTemplate="%s | Team Member">
            <title>{title}</title>
            <meta name="description" content={title} />
          </Helmet>
        }
      />
    </Layout>
  )
}

TeamMember.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object
  })
}

export default TeamMember

export const pageQuery = graphql`
  query TeamMemberByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        order
        date(formatString: "MMMM DD, YYYY")
        title
        position
        image {
          absolutePath
        }
      }
    }
  }
`
