import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import tw, { css } from 'twin.macro'
import { gsap } from 'gsap'
import { parse } from 'marked'
import { toRem, mediaQuery } from '../styles'

import Layout from '../components/Layout'

const teamListStyles = css`
  ${tw`h-full w-full flex flex-wrap justify-center items-center uppercase relative`}
  gap: ${toRem(8)} ${toRem(18)};
`

const flexListItemStyles = css`
  ${tw`flex`}
  gap: ${toRem(10)};
`

const teamTitleStyles = css`
  ${tw`self-end justify-self-end place-self-end uppercase font-title font-thin leading-tight z-10`};
  font-size: ${toRem(32)};
  white-space: nowrap;
  ${mediaQuery(
    `
    font-size: ${toRem(45)};
  `,
    'laptop'
  )}
`

const teamPositionStyles = css`
  ${tw`font-team font-thin uppercase leading-tight mt-2 lg:mt-2 z-10`};
  font-size: ${toRem(12)};
  ${mediaQuery(
    `
    font-size: ${toRem(17)};
  `,
    'laptop'
  )}
`

const teamImageStyles = css`
  ${tw`absolute object-cover w-56 opacity-0 z-0 pointer-events-none`}
  width: ${toRem(250)};
  height: ${toRem(350)};
  transform: translateX(-50%) translateY(-50%);
  top: 50%;
  left: 50%;
  filter: brightness(0.8);
`

const TeamMemberTemplate = ({ helmet, title, position, image }) => {
  const teamImageRef = useRef()

  const handleOnMouseEnter = (e) => {
    const teamImageEl = teamImageRef.current

    if (teamImageEl) {
      gsap.to(teamImageEl, { autoAlpha: 1 })
      gsap.set(teamImageEl, {
        y: (e.clientY = -50),
        x: (e.clientX = -50),
        rotate: (e.clientX = 5)
      })
    }
  }

  const handleOnMouseLeave = () => gsap.to(teamImageRef.current, { autoAlpha: 0 })

  return (
    <ul css={teamListStyles}>
      {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
      <li
        key={title}
        css={flexListItemStyles}
        onMouseEnter={(e) => handleOnMouseEnter(e)}
        onMouseLeave={() => handleOnMouseLeave()}
      >
        {image && <img ref={teamImageRef} css={teamImageStyles} src={image} alt={title} />}
        {title && <span css={teamTitleStyles}>{title}</span>}
        {position && (
          <span css={teamPositionStyles} dangerouslySetInnerHTML={{ __html: parse(position) }} />
        )}
      </li>
    </ul>
  )
}

TeamMemberTemplate.propTypes = {
  title: PropTypes.string,
  position: PropTypes.string,
  image: PropTypes.string,
  helmet: PropTypes.object
}

export default TeamMemberTemplate
